document.addEventListener("alpine:init", () => {
  console.log("alpine is ready to go");

  Alpine.data("rando", () => ({
    commander: null,
    themes: null,
    edhRecCard: null,
    devMode: false,

    clearCache() {
      localStorage.clear();
    },

    scrollToResult() {
      const container = document.querySelector(".home__result");
      container.scrollIntoView({
        behavior: "smooth",
      });
    },

    getSafeName(commander) {
      const punctuation = /[\.,?!']/g;
      let safeName = commander.name.replaceAll(punctuation, "");
      safeName = safeName.toLowerCase().replaceAll(" ", "-");

      return safeName;
    },

    preloadThemeLinks() {
      if (!this.themes) return;

      const test = this.themes.items.map((theme) => {
        return `https://edhrec.com${theme.href}`;
      });

      if (
        HTMLScriptElement.supports &&
        HTMLScriptElement.supports("speculationrules")
      ) {
        const scriptElem = document.createElement("script");
        scriptElem.type = "speculationrules";
        const scriptText = {
          prerender: [
            {
              source: "list",
              urls: test,
            },
          ],
        };
        scriptElem.textContent = JSON.stringify(scriptText);
        document.body.append(scriptElem);
      }

      console.log(test);
    },

    async getEdhRecData(commander) {
      const safeName = this.getSafeName(commander);
      console.log(safeName);

      const url = `https://json.edhrec.com/pages/commanders/${safeName}.json`;

      const res = await fetch(url);
      const card = await res.json();

      const themes = card.panels.links.find(
        (panel) => panel.header === "Themes"
      );

      this.edhRecData = card;
      this.themes = themes;
      this.preloadThemeLinks();
      this.edhRecCard = card.container.json_dict.card;
    },

    async getCommander() {
      console.log("hello world");
      const cardCache = localStorage.getItem("randocommando");

      if (cardCache) {
        const cacheObject = JSON.parse(cardCache);
        const cacheTime = cacheObject.timeAdded;
        const currentTime = Date.now();

        // set time to clear cache and get new card
        const cacheLimit = 300000;

        console.log(currentTime - cacheTime);

        if (!(currentTime - cacheTime >= cacheLimit)) {
          this.commander = cacheObject.card;
          this.getEdhRecData(cacheObject.card);
          setTimeout(() => {
            this.scrollToResult();
          }, 400);
          return;
        }
      }

      const url = "https://api.scryfall.com/cards/random?q=is:commander";

      const res = await fetch(url);
      const card = await res.json();

      this.commander = card;
      this.getEdhRecData(card);
      localStorage.setItem(
        "randocommando",
        JSON.stringify({ timeAdded: Date.now(), card })
      );

      setTimeout(() => {
        this.scrollToResult();
      }, 400);
    },
  }));
});
